
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.simple-card-grid {
  width: 100%;
}

.simple-card-grid__layout {
  margin-top: $spacing * 2;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
  }
}

.simple-card-grid__item--highlight {
  width: 100%;
  margin: 0;

  @include mq(l) {
    width: col(6, 14);
  }
}

.simple-card-grid__items {
  width: 100%;
  padding: 0;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    width: col(7, 14);
    margin-left: col(1, 14) !important; /* stylelint-disable-line */
  }
}

.simple-card-grid__item {
  width: 100%;

  @include mq(l) {
    width: col(3, 7);
    // min-width: 27rem;
  }
}

.simple-card-grid__btn {
  display: flex;
  justify-content: flex-end;
}
