
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































































.content-pagination {
  position: relative;
}

.content-pagination__page,
.content-pagination__dot {
  color: $c-gray-medium;
  user-select: none;
}

.content-pagination__dot {
  margin: 0 $spacing / 4;
}

.content-pagination__page {
  @extend %fw-bold;
  @extend %ff-alt;

  padding: 0 $spacing / 4;
  cursor: pointer;

  &:hover {
    color: $c-green-light;
  }

  &.is-active {
    color: $c-green-dark;
    border-bottom: 2px solid $c-green-light;
    cursor: default;
  }
}
