
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































.content-filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq(s) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.content-filters-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @include mq(m) {
    flex-direction: row;
  }
}

.content-filters__dropdown {
  position: relative;

  @include mq($until: s) {
    width: 100%;
  }

  @include mq($until: m) {
    & + & {
      top: -1px;
      border-top: 0;
    }
  }

  @include mq(m) {
    & + & {
      left: -1px;
      border-left: 0;
    }
  }
}

.content-filters__name {
  position: relative;
  width: 100%;

  @include mq($until: s) {
    top: -2px;
  }

  @include mq(s) {
    width: 340px;
  }
}

.content-filters__name__input {
  @extend %fw-bold;

  position: relative;
  width: 100%;
  padding: $spacing $spacing * 3 $spacing $spacing;
  color: $c-green-dark;
  font-size: 1.6rem;
  line-height: 1.2em;
  border: 1px solid $c-gray-light;
  box-shadow: 0 0 3px 2px $c-transparent;
  transition: box-shadow 0.3s ease-out;
  cursor: pointer;

  @include mq(l) {
    user-select: none;
  }

  &:focus {
    box-shadow: 0 0 3px 2px rgba($c-green-light, 0.25);
  }
}

.content-filters__name__icon {
  position: absolute;
  top: 50%;
  right: $spacing;
  display: block;
  width: 24px;
  height: 24px;
  fill: $c-green-dark;
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%) rotate(0);
  transition: fill 0.2s ease-out, transform 0.2s ease-out;
  will-change: transform;
}
