
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.members-card {
  @extend %ff-alt;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: $spacing;
  color: $c-green-dark;
  // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='#efefef' stroke-width='4' stroke-dasharray='2%2c 15' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.members-card__logo {
  position: relative;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0FF' stroke-width='4' stroke-dasharray='2%2c 15' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  &::before {
    content: '';
    display: block;
    padding-top: calc(18 / 25 * 100%);
  }
}

.members-card__logo__placeholder {
  @extend %text-center;
  @extend %fw-bold;
  @include center-xy;

  color: $c-gray-medium;
  font-size: 2.6rem;
  line-height: 1.2em;
  user-select: none;
}

.members-card__logo__picture,
[class*='members-card__logo__picture--'] {
  @include image-fit(contain, center);

  top: $spacing * 2;
  left: $spacing * 2;
  width: calc(100% - #{$spacing * 4});
  height: calc(100% - #{$spacing * 4});

  &[class*='--placeholder'] {
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 100%;
  }
}

.members-card__dev {
  margin-top: $spacing;
  color: $c-dev;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.members-card__title {
  @extend %fw-bold;

  flex-grow: 1;
  width: 100%;
  margin-top: $spacing;
  font-size: 2rem;
  line-height: 1.5em;
}

.members-card__sector {
  @extend %fw-bold;
  @extend %text-uppercase;

  width: 100%;
  margin-top: $spacing * 2;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.members-card__country {
  @extend %text-uppercase;

  width: 100%;
  margin-top: $spacing / 2;
  color: $c-gray-dark;
  font-size: 1.3rem;
  line-height: 1.2em;
}
