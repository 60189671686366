
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































.content-search {
  position: relative;
}

.content-search__filters,
.content-search__grid,
.content-search__noresults,
.content-search__pagination {
  width: col(14);
  margin: 0 col(1);
}

.content-search__grid,
.content-search__noresults,
.content-search__pagination {
  margin-top: $spacing * 2;

  @include mq(s) {
    margin-top: $spacing * 3;
  }

  @include mq(m) {
    margin-top: $spacing * 5;
  }

  @include mq(xl) {
    margin-top: $spacing * 7;
  }
}

.content-search__grid {
  display: flex;
  flex-wrap: wrap;
}

.content-search__grid__card {
  width: 100%;

  @include mq($until: xs) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(xs, s) {
    width: calc(50% - #{$spacing * 2 / 2});

    &:nth-child(2n + 2) {
      margin-left: $spacing * 2;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s, l) {
    width: calc(33.33% - #{$spacing * 3 * 2 / 3});

    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $spacing * 3;
    }

    &:nth-child(n + 4) {
      margin-top: $spacing * 3;
    }
  }

  @include mq(l) {
    width: calc(25% - #{$spacing * 4 * 3 / 4});

    &:nth-child(4n + 2),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: $spacing * 4;
    }

    &:nth-child(n + 5) {
      margin-top: $spacing * 4;
    }
  }
}

.content-search__noresults {
  @extend %text-center;
  @extend %text-uppercase;
  @extend %fw-bold;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 410px;
  padding: $spacing * 2 0;
  color: $c-gray-medium;
  font-size: 2.6rem;
  line-height: 1.2em;
  border: 1px dashed $c-gray-light;
}

.content-search__pagination {
  display: flex;
  justify-content: center;
}
